import React from "react";
import Layout from "../components/Layout";
import errorImage from "../assets/images/error-404.png";
import {
  Container,
  Heading,
  Text,
  HStack,
  Button,
  Link,
} from "@chakra-ui/react";
import { UiLink } from "../components";
import useTranslation from "next-translate/useTranslation";
import NextImage from "next/image";

type Props = {
  title?: string | null;
  description?: string | null;
};

const Error404 = ({ title, description }: Props): JSX.Element => {
  const { t } = useTranslation("error");

  return (
    <Layout title={title || t("page-not-found")}>
      <Container textAlign="center" py={20}>
        <NextImage alt="Help" src={errorImage} />
        <Heading mt={6}>{description || t("page-not-found-desc")}</Heading>
        <Text mb={4} mt={10}>
          {t("help-desc")}
        </Text>
        <HStack justifyContent="center" spacing={2}>
          <UiLink href="/features">
            <Button as={Link}>{t("common:features")}</Button>
          </UiLink>
        </HStack>
      </Container>
    </Layout>
  );
};

export default Error404;
